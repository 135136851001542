@use "@angular/material" as mat;
$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Raleway",
);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,400;0,600;0,800;0,900;1,100;1,300;1,600;1,800&display=swap");
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
@import "./assets/styles/buttons.scss";
@import "./assets/styles/font-style.scss";
@import "stream-chat-angular/src/assets/styles/v2/scss/index.scss";

@mixin simpleSpace {
  // margin and padding values
  $spacings: (0, 0.25rem, 0.5rem, 1rem, 1.5rem, 2rem) !default;

  // margin and padding shorthand prefixes
  $prefixes: (
    p: padding,
    px: (
      padding-left,
      padding-right,
    ),
    py: (
      padding-top,
      padding-bottom,
    ),
    pt: padding-top,
    pr: padding-right,
    pb: padding-bottom,
    pl: padding-left,
    m: margin,
    mx: (
      margin-left,
      margin-right,
    ),
    my: (
      margin-top,
      margin-bottom,
    ),
    mt: margin-top,
    mr: margin-right,
    mb: margin-bottom,
    ml: margin-left,
  ) !default;

  // Loop generating all spacing styles
  @each $attr-short, $attr-list in $prefixes {
    @each $space in $spacings {
      .#{$attr-short}-#{ index(($spacings), $space)-1 } {
        @each $attr in $attr-list {
          #{$attr}: #{$space} !important;
        }
      }
    }
  }
}

@include simpleSpace();
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow: auto !important;
  min-width: 100% !important;
}

/* Angular Material Component */
.mat-drawer-container,
.mat-drawer-inner-container {
  background-color: white !important;
}
.mat-drawer-container {
  height: 100%;
}
.mat-expansion-panel-body {
  padding: 1rem !important;
}
.mat-form-field-infix {
  border-bottom: 1px solid !important;
}
.mat-select-panel {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  background-color: white;
}
.mat-dialog-actions {
  background-color: white;
}
.mat-button .mat-button-wrapper > #mat-calendar-button-0 {
  color: #ff3d77;
  font-weight: bold;
}
.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-selected {
  background-color: #ff3d77;
  color: #fff;
}
/* Angular Material Component */

// .text-center {
//   text-align: center;
// }
// .circle {
//   border-radius: 50%;
// }
// .icon-display {
//   transform: scale(2);
// }
.rounded {
  border-radius: 5px;
}
.overflow-auto {
  overflow-y: auto;
}
// .h-0 {
//   height: 0;
// }
// .mh-100 {
//   min-height: 100%;
// }
.w-100 {
  width: 100%;
}
// .mb-10 {
//   margin-bottom: 10px;
// }
// .text_lightblue {
//   color: #FF3D77;
// }
.lightBlue {
  color: white;
  background-color: #ff3d77;
}
.colored {
  background-color: #ff3d77;
  color: white;
}
// .img_profile {
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
// }
.bg-grey {
  background-color: #dddddd;
}
.activeLink {
  color: #ff3d77 !important;
  font-weight: 900 !important;
}
/* flex */
// .d-flex {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
// }
// .flex-column {
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
// }
// .flex-row {
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
// }
// .align-items-center {
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
// }
// .justify-content-end {
//   justify-content: flex-end;
// }
// .justify-content-around {
//   justify-content: space-around;
// }
// .justify-content-center {
//   justify-content: center;
// }
// .justify-content-between {
//   justify-content: space-between;
// }
// .align-self-center {
//   align-self: center;
// }
// .align-self-end {
//   align-self: end;
// }
// .flex-fill {
//   -ms-flex: 1 1 auto;
//   flex: 1 1 auto;
// }

// .btn-file {
//   padding: 0.375rem 0.75rem;
//   border-radius: 30px;
//   border: none;
//   mat-icon {
//     vertical-align: middle;
//   }
// }

/* Style Calendar in Agenda */
.calendar-sidebar {
  background-color: #ff3d77 !important;
  box-shadow: 5px 0 18px -3px #ff3d77 !important;
}
.calendar-sidebar > .month-list > .calendar-months > li.active-month,
#eventListToggler,
.calendar-sidebar > span#sidebarToggler,
.calendar-sidebar > .month-list > .calendar-months > li:hover {
  background-color: #0668d1 !important;
}
th[colspan="7"] {
  color: #0f3564 !important;
}
th[colspan="7"]::after {
  background-color: rgba(23, 45, 81, 0.15) !important;
}
.event-list > .event-empty > p {
  color: #0f3564 !important;
}
.event-list > .event-empty {
  background-color: rgba(23, 45, 81, 0.15) !important;
  border: 1px solid #0f3564 !important;
}
.calendar-inner::after {
  background-color: rgba(23, 45, 81, 0.15) !important;
}
.calendar-sidebar > span#sidebarToggler,
.evo-calendar {
  box-shadow: 5px 0 18px -3px #ff3d77 !important;
}
#eventListToggler {
  box-shadow: 5px 0 18px -3px #ff3d77 !important;
}
.calendar-sidebar > .calendar-year,
.calendar-sidebar > .month-list {
  background-color: #ff3d77 !important;
}
@media screen and (max-width: 768px) {
  .calendar-events {
    box-shadow: -5px 0 18px -3px rgb(23 45 81 / 50%) !important;
  }
}
/* Style Calendar in Agenda */

/* Zoom Web SDK */
#zoom-content {
  #zmmtg-root {
    .meeting-client {
      .meeting-client-inner {
        #wc-content {
          #wc-container-left {
            .video-share-layout {
              height: 100%;
              max-height: 500px;
            }
          }
        }
        .bhold {
          .wr-content {
            .wr-tile {
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
}

.gallery-video-container__main-view {
  margin-top: 0 !important;
}
#zmmtg-root {
  display: none;
  position: relative !important;
  background-color: #ffffff !important;
}
.mini-layout-body {
  text-align: center !important;
}
.footer-button-base__button[aria-label="Reazioni"],
.footer-button-base__button[aria-label="Impostazioni"] {
  display: none;
}
.media-preview-container,
.media-preview-container > p {
  margin: 0 !important;
}
.root-inner {
  position: relative;
}
#content_container,
.av-preview-container {
  height: auto !important;
  width: 100% !important;
}
#media-preview-camera-on {
  display: block !important;
}
.meeting-client,
.meeting-client-inner {
  width: 100%;
  position: relative !important;
}
#zoom-content {
  width: 60%;
  display: none;
}
#content,
.media-preview-container,
.meeting-app,
.join-dialog,
#wc-footer,
#wc-leave {
  width: 100% !important;
}
.footer-button__button-label,
.join-audio span {
  color: rgba(255, 255, 255, 0.5) !important;
}
#wc-footer-left {
  display: flex;
}
#wc-container-left.show-participants {
  width: 70% !important;
}
#wc-container-right {
  width: 30% !important;
  position: absolute !important;
  bottom: 0;
  right: 0;
  min-height: 300px;
}
/* Zoom Web SDK */

// Pia images circle
.img-profile-container {
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  border: 1px solid #ff3d77;
  &.activeDoc {
    border: 3px solid #ff3d77;
    padding: 1px;
  }
  img.img-profile {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
  }
}
.bolder-border {
  border: 5px solid #ff3d77;
  &.light-blue {
    border-color: #ff3d77;
  }
}
.img-profile-container {
  &.small {
    width: 40px;
    height: 40px;
  }
  &.medium {
    width: 50px;
    height: 50px;
  }
  &.large {
    width: 87px;
    height: 87px;
    min-width: 87px;
  }
  &.big-border {
    border: 3px solid #ff3d77;
  }
  &.width82 {
    width: 82px;
    height: 82px;
  }
}

// Shared components
app-user-info {
  display: flex;
  place-content: center flex-end;
  flex-direction: row;
  flex: 1 1 0;
  align-items: center;
}

mat-form-field {
  &.do3-input {
    width: 100%;
  }
  input {
    border: none;
    width: 100%;
  }
  input:focus,
  input:active,
  input:focus-within {
    outline: none;
    border: none;
  }
}

.right-border {
  border-right: 1px solid #ff3d77;
}
.left-border {
  border-left: 1px solid #ff3d77;
}

.container.main-container {
  min-width: 94%;
}

.margin140 {
  margin-top: 140px;
  margin-bottom: 140px;
}

/* Bootstrap */
.modal-backdrop {
  z-index: 0 !important;
}

#staticBackdrop {
  .modal-body {
    text-align: center;
    p {
      color: #2f2f2f;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    mat-icon {
      color: #ff3d77;
      font-size: 60px;
      width: auto;
      height: auto;
    }
    textarea {
      border-bottom: 1px solid;
      border-top: none;
      border-right: none;
      border-left: none;
    }
    .note {
      p {
        font-size: 16px;
      }
    }
  }
  .modal-footer {
    border-top: none;
  }
  .modal-header {
    border-bottom: none;
  }
  button {
    &.do3-secondary-btn {
      background-color: #ff3d77;
      color: #ffffff;
      width: 200px;
      text-transform: uppercase;
    }
    &.border-lightBlue {
      border: 1px solid #ff3d77;
      background-color: white;
      color: #ff3d77;
      border-radius: 24px;
      width: 100px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ff3d77;
        color: white;
      }
    }
    &.clicked {
      background-color: #ff3d77;
      color: white;
    }
  }
}
